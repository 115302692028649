.content {
}

.top {
    width: 100%;
    position: relative;
    height: 150px;
    text-align: center;
}

.register-title {
    font-size: 25px;
    position: absolute;
    bottom: 0;
    color: #333;
    margin: 0;
}

.form {
    width: 500px;
    height: auto;
    margin: 0 auto;
}

.toLogin {
    position: absolute;
    right: 200px;
    bottom: 0;
}

hr {
    width: 100%;
    margin: 20px,200px, 20px, 200px;
    border: 0;
    border-top: 1px solid #eee;
}