.ant-form-explain {
    display: flex;
}
.login {
    display: flex;
    justify-content: space-around;
    align-items: center;
    /*background-image: url(../../img/bg_main.jpg);*/
    height: 100%;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
}

.login-form {
    width: 100%;
}

.loginContent {
    background-color: rgb(255, 255, 255);
    display: flex;
    /* justify-content: center; */
    flex-direction: column;
    /*margin: 200px auto auto auto;*/
    padding: 50px 0px;
    width: 100%;
    box-sizing: border-box;
}

.formItem {
    width: 100%;


}

.submit {
    width: 30%;
    height: 45px;
    background-color: #000000;
    border-color: #000000;
}

.title {
    font-family: sans-serif;
    font-size: 50px;
    font-style: italic;
    color: rgb(255, 255, 255);
    margin-left: 200px;
    margin-bottom: 200px;
}
.menu-title {
    /*background: url("../../img/menu_title.png");*/
    /*background-size: cover;*/
    width: 100%;
}
.menu-title img {
    width: 100%;
}
.up-title  {
    width: 100%;
}
.left-content {
    width: 35%;
    height: 100%;
}
.up-form {
    height: 90%;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 0 25%;
}
.compony {
    width: 100%;
    text-align: left;
    margin-top: 5%;
    color: rgba(0, 0, 0, 0.5);
}
.down-btn {
    text-align: left;
}
.down-form {
    display: flex;
    padding: 0 25%;
    height: 10%;
    justify-content: center;
    align-items: center;
}
.down-form img {
    width: 5%;
    margin-right: 5%;
}
