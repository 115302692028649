.body {
    margin: 0 0 0 0;
}
.h100 {
    height: 100%;
    padding-top: 55px;
}

.header-icon {
    vertical-align: 0 !important
}

.header-step {
    line-height: 1 !important
}

.footer {
    padding: 0 0 10px 0 !important;
    margin: 0 !important;
    align-content: center;
    text-align: center;
}

.footer-desc {
    font-size: 12px;
    color: #666666;
    display: inline-block;
    width: 100%;
}

.ant-breadcrumb {
    margin-top: -43px !important;
    margin-left: 35px !important;
    font-size: 15px !important;
}

.content {
    /* height: -webkit-fill-available; */
    margin-top: -2px;
    
    height: 100%;
    min-height: 890px;
}


.mysider {
    background: #ffffff;
    /* height: -webkit-fill-available; */
    
}

.mymenu {
    height: 100%;
    
}

.right-content {
    height: 100%;

}
.ant-menu-item-selected  {
    background-color: #6599ff !important;
    color: #ffffff;
}

.ant-menu-item-selected:hover {
    color:#ffffff !important;
}

.ant-menu-item-selected  > a:hover{
    color:#ffffff !important;
}

.ant-menu-item-selected  > a{
    color:#ffffff !important;
}

.header {
    display: flex;
    flex-direction: row;
    align-items: center;
    background: #5966c6;
    color: #ffffff;
    padding: 10px 0 10px 0;
    position: absolute;
    top: 0;
    right: 0;
    left: 0;
    z-index: 1;
    background-image: url(../img/bg_main.jpg);
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
}

.header-title {
    margin-left: 20px;
    font-size: 25px;
    font-weight: 800;
}

.icon-text-margin {
    margin-left: 5px;
}

.user-info {
    position: absolute;
    right: 0;
    margin-right: 120px;
}

.logout {
    position: absolute;
    right: 0;
    margin-right: 20px;
}