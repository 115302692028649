body {
  margin: 0;
  padding: 0;
  width: 100%;
  height: 100%;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

#root {
  width: 100%;
  height: 100%;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.ant-form-explain {
    display: flex;
}
.login {
    display: flex;
    justify-content: space-around;
    align-items: center;
    /*background-image: url(../../img/bg_main.jpg);*/
    height: 100%;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
}

.login-form {
    width: 100%;
}

.loginContent {
    background-color: rgb(255, 255, 255);
    display: flex;
    /* justify-content: center; */
    flex-direction: column;
    /*margin: 200px auto auto auto;*/
    padding: 50px 0px;
    width: 100%;
    box-sizing: border-box;
}

.formItem {
    width: 100%;


}

.submit {
    width: 30%;
    height: 45px;
    background-color: #000000;
    border-color: #000000;
}

.title {
    font-family: sans-serif;
    font-size: 50px;
    font-style: italic;
    color: rgb(255, 255, 255);
    margin-left: 200px;
    margin-bottom: 200px;
}
.menu-title {
    /*background: url("../../img/menu_title.png");*/
    /*background-size: cover;*/
    width: 100%;
}
.menu-title img {
    width: 100%;
}
.up-title  {
    width: 100%;
}
.left-content {
    width: 35%;
    height: 100%;
}
.up-form {
    height: 90%;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 0 25%;
}
.compony {
    width: 100%;
    text-align: left;
    margin-top: 5%;
    color: rgba(0, 0, 0, 0.5);
}
.down-btn {
    text-align: left;
}
.down-form {
    display: flex;
    padding: 0 25%;
    height: 10%;
    justify-content: center;
    align-items: center;
}
.down-form img {
    width: 5%;
    margin-right: 5%;
}

.textTitle {
    font-size: 30px;
}
.container {
    text-align: center;
    height: 100%;
    width: 100%;
    background-size: cover;
    position: relative;
    top: 0px;
    bottom: 0px;
  }

.ant-menu-item-selected {
    color: #ffffff;
}

.h100 {
  height: 100%;
}
.right-img {
    background: url(../../static/media/bg_new_main.fae5d2a5.png);
    background-size: cover;
    width: 65%;
    height: 100%;
}

.content {
}

.top {
    width: 100%;
    position: relative;
    height: 150px;
    text-align: center;
}

.register-title {
    font-size: 25px;
    position: absolute;
    bottom: 0;
    color: #333;
    margin: 0;
}

.form {
    width: 500px;
    height: auto;
    margin: 0 auto;
}

.toLogin {
    position: absolute;
    right: 200px;
    bottom: 0;
}

hr {
    width: 100%;
    margin: 20px,200px, 20px, 200px;
    border: 0;
    border-top: 1px solid #eee;
}
.body {
    margin: 0 0 0 0;
}
.h100 {
    height: 100%;
    padding-top: 55px;
}

.header-icon {
    vertical-align: 0 !important
}

.header-step {
    line-height: 1 !important
}

.footer {
    padding: 0 0 10px 0 !important;
    margin: 0 !important;
    align-content: center;
    text-align: center;
}

.footer-desc {
    font-size: 12px;
    color: #666666;
    display: inline-block;
    width: 100%;
}

.ant-breadcrumb {
    margin-top: -43px !important;
    margin-left: 35px !important;
    font-size: 15px !important;
}

.content {
    /* height: -webkit-fill-available; */
    margin-top: -2px;
    
    height: 100%;
    min-height: 890px;
}


.mysider {
    background: #ffffff;
    /* height: -webkit-fill-available; */
    
}

.mymenu {
    height: 100%;
    
}

.right-content {
    height: 100%;

}
.ant-menu-item-selected  {
    background-color: #6599ff !important;
    color: #ffffff;
}

.ant-menu-item-selected:hover {
    color:#ffffff !important;
}

.ant-menu-item-selected  > a:hover{
    color:#ffffff !important;
}

.ant-menu-item-selected  > a{
    color:#ffffff !important;
}

.header {
    display: flex;
    flex-direction: row;
    align-items: center;
    background: #5966c6;
    color: #ffffff;
    padding: 10px 0 10px 0;
    position: absolute;
    top: 0;
    right: 0;
    left: 0;
    z-index: 1;
    background-image: url(../../static/media/bg_main.d49ac591.jpg);
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
}

.header-title {
    margin-left: 20px;
    font-size: 25px;
    font-weight: 800;
}

.icon-text-margin {
    margin-left: 5px;
}

.user-info {
    position: absolute;
    right: 0;
    margin-right: 120px;
}

.logout {
    position: absolute;
    right: 0;
    margin-right: 20px;
}
.ant-btn-danger {
    color: #fff;
    background-color: #ff4d4f;
    border-color: #ff4d4f;
}

.toolbar {
    display: flex;
    margin-bottom: 10px;
}
.search {
    position: absolute;
    width: auto;
    right: 0px;
    display: inline;
    margin-right: 30px;
}

.filter {
    width: 100px;
}
.ant-modal-title {
    text-align: center;
}
.item {
    display: flex;
}
.item_margin {
    margin-top: 20px;
}
.label{
    width: 80px;
    padding-top: 6px;
    font-weight: 900;
    text-align: right;
    margin-right: 10px;
}
.right {
    width: 100%;
    align-content: end;
    text-align: end;
    right: 40px;
    padding: 0 0 10px 0;
    margin-top: 20px;
}
.avatar-uploader > .ant-upload {
    width: 128px;
    height: 128px;
  }
.upload {
    border: 1px dashed #d9d9d9;
    width: 102px;
    min-height: 77px;
    
    border-radius: 4px;
    background-color: #fafafa;
    text-align: center;
    cursor: pointer;
    -webkit-transition: border-color 0.3s ease;
    transition: border-color 0.3s ease;
    vertical-align: top;
    margin-right: 8px;
    margin-bottom: 8px;
    display: table;
    position: relative;
    background: #f7f5f5 !important;
}
.image {
    width: 102px;
    /* height: 100%; */
    min-height: 77px;
}

.input {
    opacity: 0;
    width: 100%;
    position: absolute;
    overflow: hidden;
    top: 0;
    left: 0;
    height: 100%;
}

.matchparent {
    height: 100%;
    width: 100%;
    position: relative;
    display: table-cell;
    vertical-align: middle;
}


