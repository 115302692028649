.container {
    text-align: center;
    height: 100%;
    width: 100%;
    background-size: cover;
    position: relative;
    top: 0px;
    bottom: 0px;
  }

.ant-menu-item-selected {
    color: #ffffff;
}

.h100 {
  height: 100%;
}
.right-img {
    background: url("../img/bg_new_main.png");
    background-size: cover;
    width: 65%;
    height: 100%;
}
