.upload {
    border: 1px dashed #d9d9d9;
    width: 102px;
    min-height: 77px;
    
    border-radius: 4px;
    background-color: #fafafa;
    text-align: center;
    cursor: pointer;
    transition: border-color 0.3s ease;
    vertical-align: top;
    margin-right: 8px;
    margin-bottom: 8px;
    display: table;
    position: relative;
    background: #f7f5f5 !important;
}
.image {
    width: 102px;
    /* height: 100%; */
    min-height: 77px;
}

.input {
    opacity: 0;
    width: 100%;
    position: absolute;
    overflow: hidden;
    top: 0;
    left: 0;
    height: 100%;
}

.matchparent {
    height: 100%;
    width: 100%;
    position: relative;
    display: table-cell;
    vertical-align: middle;
}

