.ant-modal-title {
    text-align: center;
}
.item {
    display: flex;
}
.item_margin {
    margin-top: 20px;
}
.label{
    width: 80px;
    padding-top: 6px;
    font-weight: 900;
    text-align: right;
    margin-right: 10px;
}